import { ModalProviderProps, TModalContext } from './types';
import { UseModalReturn, useModal } from '@cue/organisms';
import { LocalStorageUtil } from '@cue/utility';
import { useUser } from '@project/hooks/use-user';
import { AuthModal } from '@project/site/components/auth-modal/auth-modal';
import { RegisterSuccessModal } from '@project/site/components/register-success-modal/register-success-modal';
import { WelcomeBackModal } from '@project/site/components/welcome-back-modal/welcome-back-modal';
import { createContext, useContext, useEffect, useState } from 'react';

const defaultModalContextState = {
  openSuccessModal: () => {},
  openAuthModal: () => {},
  email: {
    setEmailToRegister: () => {},
  },
};

export const ModalContext = createContext<TModalContext>(defaultModalContextState);
export const useModalContext = () => useContext(ModalContext);

export const ModalProvider = ({ children, path, state }: ModalProviderProps) => {
  const { user } = useUser();
  const authModal = useModal();
  const successModal = useModal();
  const welcomeBackModal = useModal();
  const [registeredModalWasShown, setRegisteredModalWasShown] = useState(false);
  const [currentEventId, setCurrentEventId] = useState<string>();
  const [emailToRegister, setEmailToRegister] = useState<string>();
  const modalState =
    state && typeof state === 'object' && 'modal' in state && typeof state.modal === 'string'
      ? state.modal
      : false;

  const openModal = (type: 'success' | 'auth' | 'welcomeBack', eventId?: string) => {
    setCurrentEventId(eventId);
    let modalToOpen: UseModalReturn | undefined;

    switch (type) {
      case 'success':
        modalToOpen = successModal;
        break;
      case 'auth':
        modalToOpen = authModal;
        break;
      case 'welcomeBack':
        modalToOpen = welcomeBackModal;
        break;
      default:
        throw new Error('modal type not known');
    }

    const [modal] = modalToOpen;
    modal.current?.open();
  };

  const openSuccessModal = (eventId?: string) => {
    openModal('success', eventId);
  };

  const openAuthModal = (eventId?: string) => {
    openModal('auth', eventId);
  };

  const openWelcomeBackModal = () => {
    openModal('welcomeBack');
  };

  useEffect(() => {
    const SHOW_WELCOME_BACK_KEY = 'showed-welcomeback';
    const alreadyShown = LocalStorageUtil.get(SHOW_WELCOME_BACK_KEY);
    if (user) {
      const urlToken = new URLSearchParams(
        typeof window !== 'undefined' ? window.location.search : ''
      ).get('token');

      if (urlToken && !alreadyShown) {
        LocalStorageUtil.set(SHOW_WELCOME_BACK_KEY, true);
        openWelcomeBackModal();
      }
    }
    if (user === false) {
      LocalStorageUtil.remove(SHOW_WELCOME_BACK_KEY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  /**
   * If page state has changed and
   * if navigate was called with a state like on confirm.tsx
   */
  useEffect(() => {
    if (modalState === 'RegisterSuccess' && !registeredModalWasShown) {
      setRegisteredModalWasShown(true);
      openSuccessModal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState]);

  return (
    <ModalContext.Provider
      value={{
        openAuthModal,
        openSuccessModal,
        email: { emailToRegister, setEmailToRegister },
      }}>
      {children}

      {/**
       * Modal keys to make sure modals opens after page change
       * Must be fixed in useModal in web core
       * */}
      <AuthModal
        key={`${path}1`}
        modal={authModal}
        eventId={currentEventId}
        onClose={() => setCurrentEventId(undefined)}
      />

      <WelcomeBackModal key={`${path}2`} modal={welcomeBackModal} />

      <RegisterSuccessModal
        key={`${path}3`}
        modal={successModal}
        eventId={currentEventId}
        onClose={() => setCurrentEventId(undefined)}
      />
    </ModalContext.Provider>
  );
};
