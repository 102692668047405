import { ApiConfig, apiConfig } from '../../config/api-config';
import { HttpService } from '@cue/api';
import { LocalStorageUtil } from '@cue/utility';
import { AxiosResponse } from 'axios';
import { set } from 'lodash';
import { FieldValues } from 'react-hook-form';

export class Auth {
  readonly apiConfig: ApiConfig = apiConfig;

  async authenticate(token?: string): Promise<LocalUserData | false> {
    const response = token ? await this.checkIn(token) : await this.me();

    if (!response) {
      await this.logout();
      return false;
    }

    const {
      status,
      data: {
        analytics: { userId, customDimensions },
        person,
        subprojects,
      },
    } = response;

    if (status !== 200) {
      return false;
    }

    LocalStorageUtil.set('user-token', token);
    LocalStorageUtil.set('user', {
      token,
      userId,
      customDimensions: customDimensions[0],
      person,
      subprojects,
    });
    return { ...person, subprojects, userId };
  }

  async signOut(): Promise<boolean> {
    const result = await this.logout();
    if (!result) return false;
    return true;
  }

  async getChatToken(room: string): Promise<string | null> {
    const result = this.chatToken(room);
    if (!result) return null;

    return result;
  }

  async updateFields(payload: GeneralUserFields): Promise<string | null> {
    const token = await HttpService.post({
      url: this.apiConfig.me,
      config: {
        withCredentials: true,
      },
      data: {
        person: {
          fields: {
            ...payload,
          },
        },
      },
    });

    if (!token) {
      throw new Error();
    }

    return token.data.token;
  }

  private async checkIn(token: string) {
    const result: AxiosResponse | false = await HttpService.post({
      url: this.apiConfig.checkIn,
      data: { token },
      config: { withCredentials: true },
    }).catch(() => false);

    return result;
  }

  private async me() {
    const result: AxiosResponse | false = await HttpService.get({
      url: this.apiConfig.me,
      config: { withCredentials: true },
    }).catch(() => false);

    return result;
  }

  async checkIfRegisteredForEvent(email: string, eventId: string) {
    return HttpService.post({
      url: this.apiConfig.register,
      data: { email, project: eventId },
      config: { withCredentials: false },
    }).then(() => true);
  }

  async checkIfUserExists(email: string) {
    return HttpService.post({
      url: this.apiConfig.register,
      data: { email },
      config: { withCredentials: false },
    }).then(() => true);
  }

  async confirm(confimToken: string) {
    return HttpService.post({
      url: `${this.apiConfig.confirm}/${confimToken}`,
      data: {},
      config: { withCredentials: false },
    }).then(() => true);
  }

  async register(formValues: FieldValues, lang: string, eventId?: string) {
    const payload: any = {};
    Object.entries(formValues).forEach(([key, value]) => {
      set(payload, key, value);
    });

    if (!payload.fields) {
      payload.fields = {};
    }

    if (eventId) {
      payload.subproject = eventId;
    }

    payload.language = lang;

    return HttpService.post({
      url: this.apiConfig.register,
      data: payload,
      config: { withCredentials: true },
    }).then(() => undefined);
  }

  private async logout() {
    const result: AxiosResponse | false = await HttpService.post({
      url: this.apiConfig.logout,
      config: { withCredentials: true },
    }).catch(() => false);

    LocalStorageUtil.remove('user');
    return result;
  }

  async resendToken(email: string) {
    const result = await HttpService.post({
      url: this.apiConfig.resend,
      data: { email },
      config: {
        withCredentials: true,
      },
    });

    if (!result) {
      throw new Error();
    }

    return result;
  }

  private async chatToken(room?: string) {
    const token = await HttpService.post({
      url: `${this.apiConfig.chatToken}/chats/${room}/token`,
      config: {
        withCredentials: true,
      },
    });

    if (!token) {
      throw new Error();
    }

    return token.data.token;
  }
}

export interface AuthProps {
  apiConfig: ApiConfig;
}

export type GeneralUserFields = {
  [key: string]: string;
  country: string;
  company: string;
  interests: string;
};

export interface IPersonData {
  firstName: string;
  lastName: string;
}

export interface LocalUserData extends UserData {
  token?: string;
  userId?: string;
  subprojects?: string[];
}

export interface UserData extends IPersonData {
  salutation?: {
    de: string;
    en: string;
  };
  address?: {
    de: string;
    en: string;
  };
  fields: GeneralUserFields; // TODO & ProjectSpecificUserFields;
  language: string;
}
