import cmsLangConfig from './cms-languages-config.json';
import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

type CMSLanguageConfig = {
  languages: { code: string; name: string; key: string }[];
  defaultLanguage: string;
};
const resources: Resource = {};

(cmsLangConfig as CMSLanguageConfig).languages.forEach(
  ({ key }) => (resources[key] = require(`./${key}.json`))
);
const languages = cmsLangConfig.languages;
const defaultLanguage = cmsLangConfig.defaultLanguage;

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLanguage,
  nsSeparator: false,
  supportedLngs: cmsLangConfig.languages.map(({ key }: { key: string }) => key),
  keySeparator: '.',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});
i18n.languages = cmsLangConfig.languages.map(({ key }: { key: string }) => key);

const availableLanguages = languages.map((lang) => lang.key);
export default i18n;
export { availableLanguages, defaultLanguage, languages };
