import { Auth, GeneralUserFields } from '../../cue-api/auth';
import {
  AuthActions,
  AuthState,
  AuthenticationContextProviderProps,
  IAuthenticationContext,
} from './types';
import { useQueryParams } from '@cue/hooks';
import { useToastNotification } from '@cue/organisms';
import { LocalStorageUtil, TimeUtil } from '@cue/utility';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const AuthenticationContext = React.createContext<IAuthenticationContext>({
  state: {
    userName: '',
    preferredTimezone: null,
    userTimezone: '',
    isAuthenticated: false,
    error: null,
    loading: true,
  },
  actions: {
    login: async () => {},
    logout: async () => {},
    refetch: async () => {},
    checkIfRegistered: async () => false,
    checkIfRegisteredForEvent: async () => false,
    register: async () => {},
    confirm: async () => false,
    setPreferredTimezone: () => {},
    updateFields: async () => {},
    getChatToken: async () => null,
  },
});

export const AuthenticationProvider: React.FC<AuthenticationContextProviderProps> = (props) => {
  const [queryParams, setQueryParams] = useQueryParams();
  const userTimezone = useMemo(() => TimeUtil.getUsersTimezone(), []);
  const [preferredTimezone, _setPreferredTimezone] = useState<string | null>(
    LocalStorageUtil.get('user-preferred-timezone')
  );
  const { t } = useTranslation();
  const notification = useToastNotification();
  const auth = useMemo(() => new Auth(), []);

  // auto signin
  const {
    data: user,
    isLoading: autoLoginIsLoading,
    error: autoLoginError,
    refetch: refetchUser,
  } = useQuery({
    queryKey: ['user'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    queryFn: async () => {
      const urlToken = new URLSearchParams(
        typeof window !== 'undefined' ? window.location.search : ''
      ).get('token');

      if (urlToken) {
        const user = await auth.authenticate(urlToken);
        if (!user) {
          throw new Error('URL-Token is not valid');
        }
        return user;
      }

      const localUserToken = LocalStorageUtil.get('user-token');

      if (localUserToken) {
        const user = await auth.authenticate(String(localUserToken));
        if (!user) {
          throw new Error('Local Token is not valid');
        }
        return user;
      }

      const localUser = LocalStorageUtil.get('user');

      if (localUser) {
        const user = await auth.authenticate();

        if (!user) {
          LocalStorageUtil.remove('user');
          throw new Error('Local Token is not valid');
        }
        return user;
      }

      return false;
    },
  });

  const resetUser = React.useCallback(() => {
    if ('token' in queryParams) {
      const { token, ...rest } = queryParams;
      setQueryParams(rest);
    }
    LocalStorageUtil.remove('user-token');
    refetchUser();
  }, [queryParams, setQueryParams, refetchUser]);

  // manual sign in
  const {
    mutate: loginMutation,
    isLoading: manualLoginIsLoading,
    error: manualLoginError,
  } = useMutation({
    mutationFn: async (token?: string) => {
      const user = await auth.authenticate(token);

      if (!user) {
        throw new Error('URL-Token is not valid');
      }
      return user;
    },
    onSuccess: (data) => {
      refetchUser();
      return data;
    },
  });

  // signout
  const {
    mutate: signOutMutation,
    isLoading: signoutIsLoading,
    error: signoutError,
  } = useMutation({
    mutationFn: async () => {
      const signOutResult = await auth.signOut();
      if (!signOutResult) {
        throw new Error('Could not sign out');
      }
      resetUser();
    },
    onSuccess: (data) => {
      refetchUser();
      return data;
    },
  });

  useEffect(() => {
    if (autoLoginError || manualLoginError) {
      notification.error({
        title: t('authentication.error.title'),
        message: t('authentication.error.message'),
        duration: 5,
      });

      resetUser();
      console.error(autoLoginError || manualLoginError);
    }
  }, [autoLoginError, manualLoginError, t, notification, resetUser]);

  useEffect(() => {
    if (signoutError) {
      notification.error({
        title: t('logout.error.title'),
        message: t('logout.error.message'),
        duration: 5,
      });
      resetUser();
      console.error(signoutError);
    }
  }, [signoutError, t, notification, resetUser]);

  const state: AuthState = {
    user,
    userName: user ? `${user.firstName} ${user.lastName}` : '',
    userTimezone,
    preferredTimezone,
    isAuthenticated: Boolean(user),
    error: autoLoginError || manualLoginError || signoutError,
    loading: autoLoginIsLoading || manualLoginIsLoading || signoutIsLoading,
  };

  const actions: AuthActions = {
    login: async (token?: string) => {
      loginMutation(token);
    },
    logout: async () => {
      signOutMutation();
    },
    refetch: async () => {
      refetchUser();
    },
    checkIfRegistered: (email: string) => {
      return auth.checkIfUserExists(email);
    },
    register: (fieldValues: FieldValues, lang: string, eventId?: string) => {
      return auth.register(fieldValues, lang, eventId);
    },
    confirm: (confirmToken: string) => {
      return auth.confirm(confirmToken);
    },
    checkIfRegisteredForEvent: (email: string, eventId: string) => {
      return auth.checkIfRegisteredForEvent(email, eventId);
    },
    updateFields: async (payload: GeneralUserFields) => {
      if (user) {
        const updateValues = { ...(user?.fields || {}), ...payload };
        await auth.updateFields(updateValues);
        refetchUser();
      }
    },
    setPreferredTimezone: (timezone: string) => {
      LocalStorageUtil.set('user-preferred-timezone', timezone);
      _setPreferredTimezone(timezone);
    },
    getChatToken: (room: string) => auth.getChatToken(room),
  };

  return (
    <AuthenticationContext.Provider value={{ state, actions }}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};
