import { FormField } from '../form-field/form-field';
import { IAuthModal } from './types';
import { Button, Heading, Input, InputProps } from '@cue/atoms';
import { useForm } from '@cue/hooks';
import { Modal } from '@cue/organisms';
import { useUser } from '@project/hooks/use-user';
import { useModalContext } from '@project/providers/modal-provider';
import { navigate } from 'gatsby';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const AuthModal = (props: IAuthModal) => {
  const { eventId, modal, onClose, show } = props;
  const [authModal] = modal;
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const INPUT_INVALID_MESSAGE = t('input.invalid');
  const { checkIfRegistered, checkIfRegisteredForEvent } = useUser();
  const { email } = useModalContext();
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);

  const onSubmit = async () => {
    if (eventId) {
      try {
        await checkIfRegisteredForEvent(getValues().email, eventId);
        setAlreadyRegistered(true);
      } catch (error) {
        email.setEmailToRegister(getValues().email);
        authModal.current?.close();
        navigate(`/${language}/events/${eventId}/register`);
      }
    } else {
      try {
        await checkIfRegistered(getValues().email);
        setAlreadyRegistered(true);
      } catch (error) {
        email.setEmailToRegister(getValues().email);
        authModal.current?.close();
        navigate(`/${language}/register`);
      }
    }
  };

  const {
    form: { formState, handleSubmit, getValues, reset },
    registerElement,
  } = useForm({
    schema: yup
      .object({
        email: yup.string().email(INPUT_INVALID_MESSAGE).required(),
      })
      .required(),
  });

  return (
    <Modal
      show={show}
      ref={authModal}
      id="auth-modal"
      onVisibilityChange={(visible) => {
        if (!visible) {
          onClose();
          reset();
        }
      }}>
      {alreadyRegistered ? (
        <div className="cue-project-register-resend">{t('register.already-registered')}</div>
      ) : (
        <div className="cue-project-auth-form">
          <Heading type="h3" className="cue-project-headline">
            {t('already.registered')}
          </Heading>
          <FormField
            label={'E-mail'}
            input={registerElement<InputProps>({
              element: Input,
              name: 'email',
              props: {
                error: formState.errors.email !== undefined,
                errorMessage:
                  formState.errors.email?.message === INPUT_INVALID_MESSAGE
                    ? INPUT_INVALID_MESSAGE
                    : t('input.required'),
              },
            })}
            required
          />

          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(onSubmit)();
            }}>
            {t('send')}
          </Button>
        </div>
      )}
    </Modal>
  );
};
