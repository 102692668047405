import { IFormField } from './types';
import { useTranslation } from 'react-i18next';

export const FormField = (props: IFormField) => {
  const { t } = useTranslation();
  const {
    label,
    input,
    required = false,
    error = false,
    errorMessage = t('input.required'),
    className,
    disabledValue,
  } = props;
  return (
    <div
      className={`cue-project-form-field ${error ? 'cue-project-form-field-error' : ''} ${
        className ?? ''
      }`}>
      {label && (
        <div className="cue-project-form-field-label">
          {label}
          {required ? ' *' : ''}
        </div>
      )}
      <div>
        {disabledValue ? <div className="cue-readonly-input-element">{disabledValue}</div> : input}
        {error && <div className="cue-project-form-error-message">{errorMessage}</div>}
      </div>
    </div>
  );
};
