import { AuthenticationContext } from '../../providers/auth-provider';
import React from 'react';

export const useUser = () => {
  const authContext = React.useContext(AuthenticationContext);

  if (!authContext) {
    throw new Error('useUser can only be used within an AuthenticationProvider');
  }

  return {
    ...authContext.state,
    ...authContext.actions,
  };
};
