import { Dayjs } from '@cue/utility';
import { Category_Flat, Image } from '@project/cms/modules';
import { DirectusMenu } from '@project/hooks/use-nav-menu/types';
import { createContext } from 'react';

export type ISingleEvent = {
  content?: {
    description?: string;
    title: string;
    subline?: string;
    key_visual: any;
  };
  image?: Image;
  startDate: Dayjs;
  endDate: Dayjs;
  date_created: Dayjs;
  isPastEvent: boolean;
  categories: Category_Flat[];
  desired_user_fields: {
    field_key: string;
    is_required: boolean;
    type: string;
  }[];
  slug: {
    slug: string;
  } | null;
  submenu: DirectusMenu[];
};

export type TEvents = ISingleEvent[];

export const EventContext = createContext<TEvents>([]);
