import { Image } from './modules';
import { IGatsbyImageData, getSrc, getSrcSet } from 'gatsby-plugin-image';

export const CMS_Module_Pre_Hooks = [
  {
    type: 'content_module_image_with_text',
    callback: (data: any, _lang: string) => {
      return {
        ...data,
        image: data.image ? parseGatsbyImage(data.image) : null,
      };
    },
  },

  {
    type: 'content_module_fullscreen_slogan',
    callback: (data: any, _lang: string) => {
      return {
        ...data,
        key_visual: data.key_visual ? parseGatsbyImage(data.key_visual) : null,
      };
    },
  },
  {
    type: 'content_module_live_stream',
    callback: (data: any, _lang: string) => preHookLivestreamModule(data, _lang),
  },
  {
    type: 'content_module_livestream_teaser',
    callback: (data: any, _lang: string) => {
      return {
        ...data,
        teaser_image: data.teaser_image ? parseGatsbyImage(data.teaser_image) : null,
        livestream_content_modules: data?.livestream_content_modules?.map((lcm: any) => ({
          ...preHookLivestreamModule(
            getTranslationsForLanguage(lcm.content_module_live_stream_id, _lang),
            _lang
          ),
        })),
      };
    },
  },
  {
    type: 'content_module_accordion',
    callback: (data: any, lang: string) => {
      return {
        ...data,
        groups:
          data?.groups?.map((group: { content_module_accordion_group_id: { entries: any[] } }) => ({
            ...getTranslationsForLanguage(group?.content_module_accordion_group_id, lang),
            entries:
              group?.content_module_accordion_group_id?.entries?.map(
                (entry: { content_module_accordion_entry_id: any }) =>
                  getTranslationsForLanguage(entry?.content_module_accordion_entry_id, lang)
              ) || [],
          })) || [],
        entries:
          data?.entries?.map((entry: { content_module_accordion_entry_id: any }) =>
            getTranslationsForLanguage(entry?.content_module_accordion_entry_id, lang)
          ) || [],
      };
    },
  },
  {
    type: 'content_module_virtual_tour_selection',
    callback: (data: any, lang: string) => {
      return {
        ...data,
        ...getTranslationsForLanguage(data, lang),
        tours:
          data?.tours?.map(({ tour_id }: any) => {
            const ret = {
              ...tour_id,
              ...getTranslationsForLanguage(tour_id, lang),
            };
            delete ret.translations;
            const scene = ret.steps?.[0]?.scene?.id || null;
            delete ret.steps;

            return {
              ...ret,
              image: parseGatsbyImage(ret.image),
              scene,
            };
          }) || [],
      };
    },
  },
  {
    type: 'content_module_survey',
    callback: (data: any, lang: string) => preHookSurvey(data, lang),
  },
  {
    type: 'content_module_event_teaser',
    callback: (data: any, lang: string) => ({
      ...data,
      survey: data.survey ? preHookSurvey(data.survey, lang) : null,
    }),
  },
];

const preHookSurvey = (data: any, lang: string) => {
  return {
    ...data,
    questions: data?.questions.map(({ translations, options, ...question }: any) => ({
      ...question,
      ...getTranslationsForLanguage({ ...question, translations }, lang),
      options: options.map(({ translations, ...option }: any) => {
        const trans = getTranslationsForLanguage({ ...question, translations }, lang);
        return {
          ...option,
          ...trans,
          image: trans.image ? parseGatsbyImage(trans.image) : null,
        };
      }),
    })),
  };
};

export function getTranslationsForLanguage(pageTranslations: any, langCode: string) {
  const { translations: trans } = pageTranslations;

  if (!trans) return pageTranslations;

  const translationForCurrentLanguage = trans?.find(
    (t: { languages_code: { code: string } }) =>
      t.languages_code.code === langCode || t.languages_code.code.split('-')?.[0] === langCode
  );

  const { languages_code, ...translations } = translationForCurrentLanguage || {};
  return translations;
}

const preHookLivestreamModule = (data: any, lang: string) => {
  return {
    ...data,
    session_display: data?.session_display?.map((sd: any) => ({
      ...getTranslationsForLanguage(sd.content_module_event_display_id, lang),
    })),
    livestream_channel: {
      ...data.livestream_channel,
      categories:
        data.livestream_channel?.categories?.map((category: any) => ({
          ...category.categories_id,
        })) || [],
      category_slugs:
        data.livestream_channel?.categories?.map((category: any) => category.categories_id.slug) ||
        [],
    },
    card_image: data.card_image ? parseGatsbyImage(data.card_image) : null,
    offset_start: data.offset_start || 0,
    offset_end: data.offset_end || 0,
  };
};

export const parseGatsbyImage = ({
  id,
  title,
  width,
  height,
  filesize,
  imageFile,
}: Omit<Image, 'src' | 'srcSet'> & { imageFile: IGatsbyImageData }): Image => {
  return {
    id,
    title,
    width,
    height,
    filesize,
    src: getSrc(imageFile) || '',
    srcSet: getSrcSet(imageFile),
  };
};
