import { IRegisterSuccessModal } from './types';
import { Heading, Icon } from '@cue/atoms';
import { Modal } from '@cue/organisms';
import { useTranslation } from 'react-i18next';

export const RegisterSuccessModal = (props: IRegisterSuccessModal) => {
  const { modal, eventId, onClose, show } = props;
  const { t } = useTranslation();
  const [successModal] = modal;

  return (
    <Modal
      show={show}
      ref={successModal}
      id="success-modal"
      onVisibilityChange={(visible) => {
        if (!visible) {
          onClose();
        }
      }}>
      <div className="cue-project-info-modal">
        <div className="cue-project-info-headline">
          <Icon name="check" />
          <Heading type="h3">{t('registration.successful')}</Heading>
        </div>
        <div className="cue-project-event-registration-subline">
          {eventId ? t('event.registration-worked-subline') : t('registration.successful-subline')}
        </div>
      </div>
    </Modal>
  );
};
