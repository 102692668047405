import { IWelcomeBackModal } from './types';
import { Heading } from '@cue/atoms';
import { Modal } from '@cue/organisms';
import { useUser } from '@project/hooks/use-user';
import { useTranslation } from 'react-i18next';

export const WelcomeBackModal = (props: IWelcomeBackModal) => {
  const { modal, onClose, show } = props;
  const { t } = useTranslation();
  const { userName } = useUser();
  const [welcomeBackModal] = modal;

  return (
    <Modal
      show={show}
      ref={welcomeBackModal}
      id="welcome-back-modal"
      onVisibilityChange={(visible) => {
        if (!visible) {
          if (onClose) {
            onClose();
          }
        }
      }}>
      <div className="cue-project-info-modal">
        <div className="cue-project-info-headline">
          <Heading type="h3">{t('welcome-back').replace('{name}', userName)}</Heading>
        </div>
      </div>
    </Modal>
  );
};
